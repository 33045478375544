
            import {Router} from "@vaadin/router";
            import {isAuthenticated, isNotAuthenticated,} from "@whilecat/services/authentication-service.ts";
            import {checkIfLevelAccessible} from "@whilecat/services/user-course-service.ts";
            import {redirectTo, routeTo404, routeToLogin,} from "@whilecat/core/utils/location-utils.ts";
            import {defineCustomElement} from "@whilecat/core/utils/custom-elements-utils.js";
            import {setCurrentLanguage, SupportedLanguage} from "@whilecat/core/localization/localization-utils.ts";
            import {initSentry} from "@whilecat/core/utils/sentry/init.ts";

            setCurrentLanguage(SupportedLanguage.RU);
            initSentry()

            const outlet = document.getElementById("outlet");
            const router = new Router(outlet);

            const tsCourses = ["shell", "concurrency", "junit"];

            router.setRoutes([
                {
                    path: "/",
                    component: "landing-page",
                    action: async () =>
                        await import("./components/pages/landing/landing.js"),
                },
                {
                    path: "/login",
                    component: "login-form",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/login-form.ts"
                        );
                    },
                },
                {
                    path: "/registration",
                    component: "registration-form",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/registration-form.ts"
                        );
                    },
                },
                {
                    path: "/restore-password",
                    component: "forgot-password-form",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/forgot-password-form.ts"
                        );
                    },
                },
                {
                    path: "/reset-password",
                    component: "reset-password",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/reset-password.ts"
                        );
                    },
                },
                {
                    path: "/confirm",
                    component: "confirm-registration-form",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/confirm-registration-form.ts"
                        );
                    },
                },
                {
                    path: "/authentication/github",
                    component: "external-authentication-processing",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/external-authentication-processing.ts"
                        );
                    },
                },
                {
                    path: "/authentication/google",
                    component: "external-authentication-processing",
                    action: async () => {
                        if (isAuthenticated()) {
                            return redirectTo("/courses");
                        }
                        await import(
                            "./components/pages/security/external-authentication-processing.ts"
                        );
                    },
                },
                {
                    path: "/your-courses",
                    component: "user-course-list",
                    action: async (commands) => {
                        if (isNotAuthenticated()) {
                            return routeToLogin(commands);
                        }
                        import(
                            "./components/pages/courses/user-course-list.ts"
                        );
                    },
                },
                {
                    path: "/courses",
                    component: "course-list",
                    action: async () =>
                        await import(
                            "./components/pages/courses/course-list.ts"
                        ),
                },
                {
                    path: "/prices",
                    component: "prices-block",
                    action: async () =>
                        await import(
                            "./components/pages/prices/prices-block.js"
                        ),
                },
                {
                    path: "/repetition",
                    component: "repetition-page",
                    action: async (context, commands) => {
                        if (isNotAuthenticated()) {
                            return routeToLogin(commands);
                        }
                        await import(
                            "./components/pages/repetition/repetition.ts"
                        );
                    },
                },
                {
                    path: "/courses/:section/:course",
                    action: async (context, commands) => {
                        const course = context.params.course;
                        const section = context.params.section;

                        const ext = tsCourses.includes(course) ? "ts" : "js";
                        try {
                            await import(
                                `./components/content/${section}/${course}/levels.${ext}`
                            );
                        } catch (error) {
                            console.error(error);
                            return routeTo404(commands);
                        }
                        return commands.component(
                            `${section}-${course}-levels`,
                        );
                    },
                },
                {
                    path: "/courses/:section/:course/lesson-:lessonNumber",
                    action: async (context, commands) => {
                        const courseName = context.params.course;
                        const section = context.params.section;
                        const lessonNumber = context.params.lessonNumber;

                        if (isNotAuthenticated()) {
                            return routeToLogin(commands);
                        }

                        const ext = tsCourses.includes(courseName)
                            ? "ts"
                            : "js";

                        const course = await import(
                            `./components/content/${section}/${courseName}/levels.${ext}`
                        );
                        const lesson = await import(
                            `./components/content/${section}/${courseName}/lesson-${lessonNumber}.${ext}`
                        );

                        const accessible = await checkIfLevelAccessible(
                            course.getCourseUuid(),
                            lesson.getLevelUuid(),
                        );
                        if (!accessible) {
                            return routeTo404(commands);
                        }

                        try {
                            await import(
                                `./components/content/${section}/${courseName}/lesson-${lessonNumber}.${ext}`
                            );
                        } catch (error) {
                            console.error(error);
                            return routeTo404(commands);
                        }

                        if (Object.hasOwn(lesson, "getComponent")) {
                            defineCustomElement(
                                `${section}-${courseName}-lesson-${lessonNumber}`,
                                await lesson.getComponent(),
                            );
                        }
                        return commands.component(
                            `${section}-${courseName}-lesson-${lessonNumber}`,
                        );
                    },
                },
                {
                    path: "/404",
                    component: "not-found",
                    action: async (context) => {
                        console.error(
                            "Redirected to not found page: ",
                            context,
                        );
                        await import("./components/pages/404/not-found.ts");
                    },
                },
                {
                    path: "/profile",
                    component: "user-profile",
                    action: async () => {
                        await import("./components/pages/user/user-profile.ts");
                    },
                },
                {
                    path: "/reach-us",
                    component: "feedback-form",
                    action: async () => {
                        await import("./components/pages/feedback/feedback.ts");
                    },
                },
                {
                    path: "/level-viewer(.*)",
                    component: "level-viewer",
                    action: async () => {
                        await import("./components/dev/level-viewer.js");
                    },
                },
                {
                    path: "(.*)",
                    component: "not-found",
                    action: async (context, commands) => {
                        console.error("Not found page: ", context);
                        return routeTo404(commands);
                    },
                },
            ]);
        
export class LevelInfo {
    id: number;
    levelName: string;
    levelUrl: string;
    sourcePath: string;
    completed: boolean;

    constructor(id: number, levelName: string, levelUrl: string, sourcePath: string, completed: boolean) {
        this.id = id;
        this.levelName = levelName;
        this.levelUrl = levelUrl;
        this.sourcePath = sourcePath;
        this.completed = completed;
    }
}

import {html} from "lit";
import {requireNotNull} from "@whilecat/core/utils/validation.js";
import {isPresent} from "@whilecat/core/utils/objects.js";

export function stringToListItem(data: string) {
    requireNotNull(data)

    const paragraphs = data.split('\n')
        .filter(line => line.trim() !== '')
        .map(line => html`<li>${line}</li>`);

    return html`${paragraphs}`;
}

export function formatDuration(ms: number, skipMs: boolean) {
    requireNotNull(ms)
    requireNotNull(skipMs)

    let seconds = Math.floor(ms / 1000.0);
    ms %= 1000.0
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    let formattedTime = "";

    if (hours > 0) {
        formattedTime += hours + " hr" + (hours > 1 ? "s " : " ");
    }
    if (minutes > 0) {
        formattedTime += minutes + " min" + (minutes > 1 ? "s " : " ");
    }
    if (seconds > 0) {
        formattedTime += seconds + " sec" + (seconds > 1 ? "s " : " ");
    }
    if (ms >= 0 && !skipMs) {
        formattedTime += ms + " ms";
    }

    return formattedTime.trim();
}

export function isBlank(string?: string | null) {
    return string === undefined || string === null || string === '';
}

export function isNotBlank(string?: string | null) {
    return !isBlank(string)
}

export function isNotBlankArray(array: Array<string>) {
    if (!isPresent(array) || array.length == 0)
        return false;

    for (let i = 0; i < array.length; i++) {
        if (isBlank(array[i])) {
            return false;
        }
    }
    return true
}

export function replaceTabsWithSpaces(text: string) {
    if (isPresent(text)) {
        return text.replaceAll("\t", "    ")
    }
    return text
}

export function countOccurrences(text: string, pattern: RegExp) {
    return (text.match(pattern) || []).length;
}
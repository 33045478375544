import {isNotBlank} from "@whilecat/core/utils/string.ts";
import createClient from "openapi-fetch";
import type {paths} from "@whilecat/generated/openapi/java-compiler.js";
import {requireNotBlank} from "@whilecat/core/utils/validation.js";

let BASE_URL_TEST = ""

export function setTestBaseUrl(baseUrl: string) {
    requireNotBlank(baseUrl)
    BASE_URL_TEST = baseUrl
}

export function createWhileCatClient(timezone: string) {
    const baseUrl: string = isNotBlank(BASE_URL_TEST) ? BASE_URL_TEST as string : "/api"
    return createClient<paths>({baseUrl: baseUrl, headers: {"TimeZone": timezone}});
}
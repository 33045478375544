export class Course {
    id: number;
    uuid: string;
    name: string;
    subtitle: string;
    sourcePath: string;
    url: string;
    enrolled: boolean;

    constructor(id: number, uuid: string, name: string, url: string, sourcePath: string, enrolled: boolean) {
        this.id = id;
        this.uuid = uuid
        this.name = name;
        this.subtitle = 'test test ste';
        this.url = url;
        this.sourcePath = sourcePath;
        this.enrolled = enrolled;
    }
}
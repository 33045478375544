export function isPresent(object: any) {
    return object !== null && object !== undefined
}

export function isAbsent(object: any) {
    return !isPresent(object)
}

export function areEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export function areNotEqual(obj1: any, obj2: any) {
    return !areEqual(obj1, obj2)
}
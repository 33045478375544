import {css, html, LitElement, nothing, PropertyValues} from "lit";
import {customElement, state} from "lit/decorators.js";
import {localized, msg} from '@lit/localize';
import {getCSSVariablePixels} from "@whilecat/core/utils/screen-utils.js";
import {hasSomethingToRepeat} from "@whilecat/services/repetition-service.ts";
import {isAuthenticated} from "@whilecat/services/authentication-service.ts";

const minPageBigScreenWidth = getCSSVariablePixels(
  "--whilecat-min-page-width-big-screen",
);

// If I put localized() above customElement, the translations don't work. Even though
// it works in another element.
@customElement("navigation-panel")
@localized()
export class Navpanel extends LitElement {
  static styles = css`
    .small-menu-btn {
      display: block;
      z-index: 1000;
      position: fixed;
      left: 0;
      top: 0;
      margin: 10px;
      transition: transform 0.3s ease;
    }

    .small-menu-btn.rotate {
      transform: rotate(90deg);
    }

    .small-menu-btn.rotate::part(base) {
      border: none;
    }

    .navbar {
      position: fixed;
      left: 0;
      top: 0;
      width: var(--whilecat-navigation-panel-width);
      height: 100%;
      overflow: auto;
      border-right: solid var(--sl-color-gray-50);
      background-color: var(--whilecat-background-color);
      z-index: var(--sl-z-index-drawer);
    }

    .navbar .menu-btn {
      display: block;
      padding: 16px;
    }

    sl-button.menu-btn::part(base) {
      color: var(--whilecat-dimmed-text-color);
    }

    sl-button.menu-btn::part(base):hover {
      color: var(--sl-color-primary-700);
    }

    .navbar .links {
      text-align: center;
    }

    .navbar .brand {
      display: block;
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: var(--sl-color-primary-600);
      font-size: 12px;
    }

    .navbar .section-text {
      margin-left: 20px;
      color: var(--sl-color-neutral-400);
      font-family: var(--whilecat-default-font);
    }

    @keyframes slideIn {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }

    @keyframes slideOut {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }

    .show {
      animation: slideIn 0.5s ease forwards;
    }

    .hidden {
      animation: slideOut 0.5s ease forwards;
    }

    .notification {
      position: relative;
      border-radius: 10px;
      font-size: 13px;
      color: var(--sl-color-green-600);
      top: -5px;
      left: -7px;
    }
  `;

  @state() hiddenByDefault: boolean = false;
  @state() smallScreen: boolean = this.isSmallScreen();
  @state() repetitionAvailable: boolean = false;

  constructor() {
    super();
    window.addEventListener("resize", () => {
      this.smallScreen = this.isSmallScreen();
    });

    setInterval(async () => {
      if (isAuthenticated()) {
        this.repetitionAvailable = await hasSomethingToRepeat();
      }
    }, 60_000);
  }

  firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.smallScreen = this.isSmallScreen();
    this.addEventListener("focusout", (event) => {
      this.hide()
    });
  }

  showDrawerMenu() {
    let menuButton = this.shadowRoot!.getElementById("menu-btn");

    if (menuButton!.classList.contains("rotate")) {
      this.hide();
    } else {
      this.show();
    }
  }

  isSmallScreen() {
    return window.innerWidth <= minPageBigScreenWidth || this.hiddenByDefault;
  }

  getMenuClass(smallScreen: boolean) {
    return smallScreen ? "hidden" : "";
  }

  getSmallButtonClass(smallScreen: boolean) {
    return smallScreen ? "" : "rotate";
  }

  hide() {
    let menu = this.shadowRoot!.getElementById("menu-block");
    let menuButton = this.shadowRoot!.getElementById("menu-btn");

    menuButton!.classList.remove("rotate");
    menu!.classList.remove("show");
    menu!.classList.add("hidden");
  }

  show() {
    let menu = this.shadowRoot!.getElementById("menu-block");
    let menuButton = this.shadowRoot!.getElementById("menu-btn");

    menuButton!.classList.add("rotate");
    menu!.classList.add("show");
    menu!.classList.remove("hidden");
  }

  getUserMenu() {
    if (isAuthenticated()) {
      return html`
        <sl-button
          class="menu-btn"
          variant="text"
          size="small"
          href="/repetition"
        >
          <sl-icon slot="prefix" name="arrow-repeat"></sl-icon>
          ${msg("My Repetition")}
          ${this.repetitionAvailable
            ? html` <sl-icon
                class="notification"
                slot="suffix"
                name="stars"
              ></sl-icon>`
            : nothing}
        </sl-button>
      `;
    }
    return nothing;
  }

  render() {
    return html`
      <sl-button
        id="menu-btn"
        class="small-menu-btn ${this.getSmallButtonClass(this.smallScreen)}"
        slot="trigger"
        size="small"
        pill
        @click="${() => this.showDrawerMenu()}"
      >
        <sl-icon slot="prefix" name="list"></sl-icon>
      </sl-button>
      <div
        id="menu-block"
        class="navbar ${this.getMenuClass(this.isSmallScreen())
          ? "hidden"
          : "show"}"
      >
        <!-- Brand -->
        <h1 class="brand">
          <a href="/">
            <img src="/resources/whilecat-logo.png" alt="logo" width="100">
          </a>
          <div>(alpha-testing version)</div>
        </h1>

        <!-- External links -->
        <!--        <div class="links">-->
        <!--          <sl-button variant="default" size="small" circle>-->
        <!--            <sl-icon name="medium" label="Medium"></sl-icon>-->
        <!--          </sl-button>-->
        <!--          <sl-button variant="default" size="small" circle>-->
        <!--            <sl-icon name="instagram" label="Instagram"></sl-icon>-->
        <!--          </sl-button>-->
        <!--          <sl-button variant="default" size="small" circle>-->
        <!--            <sl-icon name="twitter-x" label="Settings"></sl-icon>-->
        <!--          </sl-button>-->
        <!--        </div>-->

        <!-- Main menu -->
        <sl-divider></sl-divider>
        <small class="section-text">${msg("General")}</small>
        <sl-button class="menu-btn" variant="text" size="small" href="/">
          <sl-icon slot="prefix" name="house"></sl-icon>
          ${msg("Home")}
        </sl-button>
        <sl-button class="menu-btn" variant="text" size="small" href="/courses">
          <sl-icon slot="prefix" name="card-list"></sl-icon>
          ${msg("Courses")}
        </sl-button>
        ${this.getUserMenu()}
          <!-- 
        <sl-button class="menu-btn" disabled variant="text" size="small" href="/prices">
          <sl-icon slot="prefix" name="wallet2"></sl-icon>
          ${msg("Pricing")}
        </sl-button>
        -->

        <!-- Help -->
          <!-- 
        <sl-divider></sl-divider>
        <small class="section-text">${msg("Info")}</small>
        <sl-button class="menu-btn" disabled variant="text" size="small" href="/about">
          <sl-icon slot="prefix" name="info-circle"></sl-icon>
          ${msg("About")}
        </sl-button>
        -->
        <sl-button
          class="menu-btn"
          variant="text"
          size="small"
          href="/reach-us"
        >
          <sl-icon slot="prefix" name="envelope-paper-heart"></sl-icon>
          ${msg("Reach us")}
        </sl-button>
      </div>
    `;
  }
}
